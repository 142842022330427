/* GOOGLE MAPS AUTOCOMPLETE START*/
.pac-container {
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  border: 1px solid #ccc;
  background-color: white;
}
.pac-item {
  padding: 10px;
  cursor: pointer;
  line-height: 25px;
  font-size: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.pac-item:hover {
  background-color: #efefef;
}

.pac-item-query {
  font-weight: bold;
}

.pac-container {
  z-index: 2000 !important; /* Adjust as needed */
}
/* GOOGLE MAPS AUTOCOMPLETE END*/